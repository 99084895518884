<template>
  <div class="col">
    <div v-if="hasPermission('access_contacts')">
      <div v-loading="loadingValue">
        <!-- //todo Start Card One  -->
        <card
          class="card-tasks mx-auto"
          style="max-width: 80rem"
          :title="`${localization('Contacts')}`"
        >
          <template #header>
            <div
              class="
                header_contact_wrapping
                d-flex
                justify-content-between
                align-items-center
                flex-wrap
              "
              :class="{ 'flex-row-reverse': isRtl() }"
              style="width: 100%"
            >
              <h4 class="card-title contact_title" :class="{ is_rtl: isRtl() }">
                <i class="nc-icon nc-credit-card"></i>

                <span class="ml-1 mr-1">{{ localization("Contacts") }}</span>
              </h4>

              <l-button
                aria-live="info"
                type="info"
                wide
                @click="search_contact_dialog = true"
                class="d-block add_contact_buttons"
              >
                {{ localization("Add New Contact") }}
              </l-button>
            </div>
          </template>
          <!-- //// Start form medium (1100px) to huge screens  -->
          <server-table
            class="server_table_for_big_screens"
            :count="totalContactListCount"
            :DataItems="contactList"
            @Refresh="getCntactsList"
            :loading="loadingValue"
            :title="`${localization('Contacts')}`"
            admin_permission="admin_users"
            edit_permission="add_tasks"
            :hidden_export_import="true"
            :action="false"
            :isContact="true"
          >
            <template #columns>
              <!-- // // user info  -->
              <el-table-column
                sortable
                prop="first_name"
                width="120px"
                :label="`${localization('First Name')}`"
              >
                <template slot-scope="scope">
                  <div :class="{ 'not-active': scope.row.status == 2 }">
                    <div class="">
                      <router-link :to="`/user-details/${scope.row.id}`">
                        {{ scope.row.first_name }}
                      </router-link>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="last_name"
                sortable
                :label="`${localization('Last Name')}`"
                width="120px"
              >
              </el-table-column>

              <el-table-column
                prop="email"
                :label="`${localization('Email')}`"
                width="180px"
              >
                <template slot-scope="scope">
                  <div class="d-flex part-one">
                    <span class="d-block user-email">{{
                      scope.row.email
                    }}</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="patterns"
                :label="`${localization('Patterns')}`"
              >
                <template slot-scope="scope">
                  <!-- User Score scope.row.can == true &&   -->
                  <div
                    v-if="scope.row.status == 1 && scope.row.public == 1"
                    class="
                      flex-grow-1 flex-wrap
                      d-flex
                      justify-content-between
                      align-items-center
                      text-capitalize
                      main-scores-container
                    "
                  >
                    <!-- Sequence  -->
                    <div class="d-flex align-items-center every-score">
                      <span class="d-block score-word">{{
                        localization("Sequence shortcut")
                      }}</span>
                      <span class="label sequence d-block score-degre">
                        {{
                          scope.row.sequence != null ? scope.row.sequence : 0
                        }}
                      </span>
                    </div>

                    <!-- Precision  -->
                    <div class="d-flex align-items-center every-score">
                      <span class="d-block score-word">{{
                        localization("precision shortcut")
                      }}</span>
                      <span class="label precision d-block score-degre">
                        {{
                          scope.row.precision != null ? scope.row.precision : 0
                        }}
                      </span>
                    </div>

                    <!-- Technical Reasoning  -->
                    <div class="d-flex align-items-center every-score">
                      <span class="d-block score-word">{{
                        localization("Technical Reasoning shortcut")
                      }}</span>
                      <span
                        class="label technical_reasoning d-block score-degre"
                      >
                        {{
                          scope.row.technical_reasoning != null
                            ? scope.row.technical_reasoning
                            : 0
                        }}
                      </span>
                    </div>

                    <!-- Confluence  -->
                    <div class="d-flex align-items-center every-score">
                      <span class="d-block score-word">{{
                        localization("Confluence shortcut")
                      }}</span>
                      <span class="label confluence d-block score-degre">
                        {{
                          scope.row.confluence != null
                            ? scope.row.confluence
                            : 0
                        }}
                      </span>
                    </div>
                  </div>
                  <div v-else>____</div>
                </template>
              </el-table-column>

              <el-table-column prop="" label="_" width="100px">
                <template slot-scope="scope">
                  <div
                    class="
                      td-actions
                      d-flex
                      justify-content-center
                      actions-icons-container
                    "
                  >
                    <i
                      v-tooltip.top-center="`${localization('Accept request')}`"
                      v-if="scope.row.can == true && scope.row.status == 2"
                      @click="acceptContact(scope.row.id)"
                      class="fa fa-check d-block actions-icons add"
                    ></i>
                    <i
                      v-tooltip.top-center="`${localization('Delete contact')}`"
                      @click="removeContact(scope.row.id)"
                      class="fa fa-times d-block actions-icons delete"
                    ></i>
                  </div>
                </template>
              </el-table-column>
            </template>
          </server-table>
          <!-- //// End form medium (1100px) to huge screens  -->
          <!-- //* Start from mobile to (100px) screens -->
          <server-table
            class="server_table_for_mobile_screen"
            :count="totalContactListCount"
            :DataItems="contactList"
            @Refresh="getCntactsList"
            :loading="loadingValue"
            :title="`${localization('Contacts')}`"
            admin_permission="admin_users"
            edit_permission="add_tasks"
            :hidden_export_import="true"
            :action="false"
            :isContact="true"
          >
            <template #columns>
              <!-- // // user info  -->
              <el-table-column
                sortable
                prop="first_name"
                :label="`${localization('First Name')}`"
              >
                <template slot-scope="scope">
                  <div :class="{ 'not-active': scope.row.status == 2 }">
                    <div class="">
                      <router-link :to="`/user-details/${scope.row.id}`">
                        {{ scope.row.first_name }}
                      </router-link>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="last_name"
                sortable
                :label="`${localization('Last Name')}`"
              >
              </el-table-column>

              <el-table-column prop="" label="_">
                <template slot-scope="scope">
                  <div
                    class="
                      td-actions
                      d-flex
                      justify-content-center
                      actions-icons-container
                    "
                  >
                    <i
                      v-if="scope.row.can == true && scope.row.status == 2"
                      @click="acceptContact(scope.row.id)"
                      v-tooltip.top-center="`${localization('Accept request')}`"
                      class="fa fa-check d-block actions-icons add"
                    ></i>
                    <i
                      @click="removeContact(scope.row.id)"
                      v-tooltip.top-center="`${localization('Delete contact')}`"
                      class="fa fa-times d-block actions-icons delete"
                    ></i>
                  </div>
                </template>
              </el-table-column>
            </template>
          </server-table>
          <!-- //* End from mobile to (100px) screens -->
        </card>
        <!-- //todo End Card One  -->

        <!--  // * Start Restored Contacts Card Two -->
        <card
          class="card-tasks mx-auto"
          style="max-width: 80rem"
          :title="`${localization('Contacts')}`"
        >
          <template #header>
            <div
              class="d-flex justify-content-between mb-1"
              style="width: 100%"
              :class="{ 'flex-row-reverse': isRtl() }"
            >
              <h4 class="card-title contact_title" :class="{ is_rtl: isRtl() }">
                <i class="nc-icon nc-credit-card"></i>
                <span>{{ localization("Restored Contacts") }}</span>
              </h4>
              <l-button
                v-if="$store.getters['contacts/contacts'].length >= 1"
                @click="invite_all_restored_contact"
                aria-live="info"
                type="info"
                wide
                class="d-block add_contact_buttons"
                >{{ localization("Invite All Contacts") }}
              </l-button>
            </div>
          </template>

          <l-table :data="$store.getters['contacts/contacts']">
            <template slot-scope="{ row }">
              <!-- //// start restored contact in large screens  -->
              <div
                class="
                  d-none d-lg-flex
                  justify-content-between
                  align-items-center
                  w-100
                  shadow-sm
                  main-members-container
                "
              >
                <div class="user-details">
                  <div class="d-flex part-one">
                    <i class="fa fa-user user-icon d-block"></i>
                    <span class="d-block user-name">
                      {{ row.name }}
                    </span>
                  </div>
                  <div class="d-flex part-one">
                    <i class="fa fa-envelope user-icon d-block"></i>
                    <span class="d-block user-name">{{ row.email }}</span>
                  </div>
                </div>

                <div
                  class="
                    flex-grow-1 flex-wrap
                    d-flex
                    justify-content-around
                    align-items-center
                    text-capitalize
                    main-scores-container-sm main-scores-container
                  "
                >
                  <div class="d-flex align-items-center every-score">
                    <span class="d-block score-word">{{
                      localization("Sequence shortcut")
                    }}</span>
                    <span class="label sequence d-block score-degre">
                      {{ row.sequence != null ? row.sequence : 0 }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center every-score">
                    <span class="d-block score-word">{{
                      localization("precision shortcut")
                    }}</span>
                    <span class="label precision d-block score-degre">
                      {{ row.precision != null ? row.precision : 0 }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center every-score">
                    <span class="d-block score-word">{{
                      localization("Technical Reasoning shortcut")
                    }}</span>
                    <span class="label technical_reasoning d-block score-degre">
                      {{
                        row.technical_reasoning != null
                          ? row.technical_reasoning
                          : 0
                      }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center every-score">
                    <span class="d-block score-word">{{
                      localization("Confluence shortcut")
                    }}</span>
                    <span class="label confluence d-block score-degre">
                      {{ row.confluence != null ? row.confluence : 0 }}
                    </span>
                  </div>
                </div>
                <!--//// reinvite restored contact  -->
                <div
                  class="
                    td-actions
                    d-flex
                    justify-content-center
                    actions-icons-container
                  "
                >
                  <i
                    @click="invite_restored_contact(row.email)"
                    v-tooltip.top-center="`${localization('Reinvite contact')}`"
                    class="
                      fa fa-user
                      d-block
                      actions-icons
                      add
                      reinvite_contact
                    "
                  ></i>

                  <i
                    @click="remove(row.id)"
                    v-tooltip.top-center="`${localization('Delete contact')}`"
                    class="fa fa-times d-block actions-icons delete"
                  ></i>
                </div>
              </div>
              <!-- //// end restored contact in large screens  -->
              <!-- //? start restored in mobile screen  -->
              <div
                class="
                  d-sm-flex d-lg-none
                  justify-content-between
                  align-items-center
                  w-100
                  shadow-sm
                  main-members-container
                "
              >
                <div class="d-flex justify-content-between">
                  <div class="user-details">
                    <div class="d-flex part-one">
                      <i class="fa fa-user user-icon d-block"></i>
                      <span class="d-block user-name">
                        {{ row.name }}
                      </span>
                    </div>
                    <div class="d-flex part-one">
                      <i class="fa fa-envelope user-icon d-block"></i>
                      <span class="d-block user-name">{{ row.email }}</span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="row.status == 1"
                  class="
                    flex-grow-1 flex-wrap
                    d-flex
                    justify-content-around
                    align-items-center
                    text-capitalize
                    main-scores-container-sm main-scores-container
                  "
                >
                  <div class="d-flex align-items-center every-score">
                    <span class="d-block score-word">{{
                      localization("Sequence shortcut")
                    }}</span>
                    <span class="label sequence d-block score-degre">
                      {{ row.sequence != null ? row.sequence : 0 }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center every-score">
                    <span class="d-block score-word">{{
                      localization("precision shortcut")
                    }}</span>
                    <span class="label precision d-block score-degre">
                      {{ row.precision != null ? row.precision : 0 }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center every-score">
                    <span class="d-block score-word">{{
                      localization("Technical Reasoning shortcut")
                    }}</span>
                    <span class="label technical_reasoning d-block score-degre">
                      {{
                        row.technical_reasoning != null
                          ? row.technical_reasoning
                          : 0
                      }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center every-score">
                    <span class="d-block score-word">{{
                      localization("Confluence shortcut")
                    }}</span>
                    <span class="label confluence d-block score-degre">
                      {{ row.confluence != null ? row.confluence : 0 }}
                    </span>
                  </div>
                </div>
                <div
                  class="
                    td-actions
                    d-flex
                    justify-content-center
                    actions-icons-container
                  "
                >
                  <i
                    @click="invite_restored_contact(row.email)"
                    v-tooltip.top-center="`${localization('Reinvite contact')}`"
                    class="
                      fa fa-user
                      d-block
                      actions-icons
                      add
                      reinvite_contact
                    "
                  ></i>
                  <i
                    v-tooltip.top-center="`${localization('Delete contact')}`"
                    @click="remove(row.id)"
                    class="fa fa-times d-block actions-icons delete"
                  ></i>
                </div>
              </div>
              <!-- //? end restored in mobile screen  -->
            </template>
          </l-table>
        </card>
        <!-- //* End Restored Contacts Card Two  -->
      </div>

      <!-- NO USE // Add & edit contacts // Search Dialog For Add  New Contact  -->
      <el-dialog
        v-if="search_contact_dialog"
        :title="type == 1 ? 'Add Contact' : 'Edit Contact'"
        :visible.sync="contact_dialog"
        width="70%"
        top="4vh"
      >
        <el-form :model="contact" :rules="rules" ref="contactRef">
          <!-- Contact Name  -->
          <el-form-item label="Name" prop="name">
            <el-input
              v-model="contact.name"
              autocomplete="off"
              style="width: 100%"
            ></el-input>
          </el-form-item>
          <!-- Contact Email  -->
          <el-form-item label="Email" prop="email">
            <el-input
              v-model="contact.email"
              type="email"
              autocomplete="off"
              style="width: 100%"
            ></el-input>
          </el-form-item>
          <!-- Contact Group  -->
          <el-form-item label="Groups" prop="groups_id">
            <el-select
              v-model="contact.groups_id"
              multiple
              clearable
              style="width: 100%"
              class="select-default"
            >
              <el-option
                v-for="(item, index) in groups"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <div class="row">
            <div class="col-sm-6 my-2">
              <!-- Sequence  -->
              <p>Sequence</p>
              <el-input-number
                label="sequence"
                class="w-100"
                v-model="contact.sequence"
                placeholder=" sequence"
              ></el-input-number>
            </div>
            <div class="col-sm-6 my-2">
              <!-- Precision  -->
              <p>Precision</p>
              <el-input-number
                label="percision"
                class="w-100"
                v-model="contact.precision"
                placeholder=" precision"
              ></el-input-number>
            </div>
            <div class="col-sm-6 my-2">
              <!-- Technical Reasoning  -->
              <p>Technical Reasoning</p>
              <el-input-number
                label="technical reasoning"
                class="w-100"
                v-model="contact.technical_reasoning"
                placeholder=" technical reasoning"
              ></el-input-number>
            </div>
            <div class="col-sm-6 my-2">
              <!-- Confluence -->
              <p>Confluence</p>
              <el-input-number
                label="confluence"
                class="w-100"
                v-model="contact.confluence"
                placeholder="confluence"
              ></el-input-number>
            </div>
          </div>
        </el-form>
        <div
          slot="footer"
          class="
            dialog-footer
            w-50
            flex-column flex-lg-row
            d-md-flex
            my-3
            ml-auto
          "
        >
          <el-button
            @click="Refresh"
            class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
            >Cancel</el-button
          >
          <el-button
            type="primary"
            @click="save"
            class="
              text-center
              px-2 px-lg-3
              ml-lg-2
              mx-auto
              d-block
              text-capitalize
              w-100
              my-1
            "
            >Save Contact</el-button
          >
        </div>
      </el-dialog>

      <!-- //? start Add new Contact  -->
      <el-dialog
        :title="`${localization('Add New Contact')}`"
        :visible.sync="search_contact_dialog"
        top="4vh"
        :class="{ 'text-right invite_contact_with_rtl': isRtl() }"
      >
        <el-form
          :model="for_add_new_contact"
          :rules="rules"
          ref="for_add_new_contact"
        >
          <el-form-item
            :label="`${localization('Email')}`"
            prop="email"
            :rules="[
              {
                required: true,
                message: 'Please input email address',
                trigger: 'blur'
              },
              {
                type: 'email',
                message: 'Please input correct email address',
                trigger: ['blur', 'change']
              }
            ]"
          >
            <el-input
              v-model="for_add_new_contact.email"
              autocomplete="off"
              style="width: 100%"
              :placeholder="`${localization('Email')}`"
              type="email"
            ></el-input>
          </el-form-item>
          <div class="shre-link mt-4">
            <h6 class="text-capitalize">{{ localization("get your link") }}</h6>
            <div
              class="d-flex align-items-center flex-wrap"
              :class="{ 'flex-row-reverse': isRtl() }"
            >
              <p class="m-0 shre-text">{{ localization("Share link") }}</p>
              <a
                href="#"
                @click.prevent="copyURL"
                class="text-dark d-block "
                target="_blank"
                rel="noopener noreferrer"
                ref="mylink"
              >
                <i class="fa fa-copy user-share-link"></i>
              </a>
            </div>
          </div>
        </el-form>
        <!-- //? Start Modal Footer Save && Cancel  -->
        <div slot="footer" class="dialog-footer main_dialog_footer">
          <el-button
            @click="closeAddNewContactModal"
            class="for_c_buttons text-center text-capitalize"
            >{{ localization("Cancel") }}</el-button
          >
          <el-button
            type="primary"
            class="text-center for_c_btns text-capitalize"
            @click="saveNewContact"
          >
            {{ localization("Save") }}</el-button
          >
        </div>
      </el-dialog>
      <!-- //? end Add new Contact  -->
    </div>

    <div v-else>
      <h2 class="text-dark text-center">
        {{
          localization("update your plan") != false
            ? localization("update your plan")
            : "Kindly update your plan to access this page"
        }}
      </h2>
    </div>
  </div>
</template>

<script>
import { Card, Table as LTable } from "src/components/index";
import Swal from "sweetalert2";

import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import { mapGetters } from "vuex";
export default {
  name: "index",
  components: {
    Card,
    LTable,
    ServerTable
  },

  data() {
    return {
      selected: [],

      request_type: "1",
      loading: false,
      contact: {},
      for_add_new_contact: {},
      type: null,
      contacts: [],
      isSerach: "",

      add_new_contact_rules: {
        contactEmail: [
          {
            required: true,
            message: [
              "Please input email address",
              "Please input correct email address"
            ],
            trigger: ["blur", "change"]
          }
        ]
      },
      rules: {
        // name: [
        //   {
        //     required: true,
        //     message: "Please this field is required",
        //     trigger: "blur"
        //   }
        // ],
        email: [
          {
            required: true,
            type: "email",
            message: [
              "Please input email address",
              "Please input correct email address"
            ],
            trigger: ["blur", "change"]
          }
        ]
        // groups_id: [
        //   {
        //     required: true,
        //     message: "Please this field is required",
        //     trigger: "change"
        //   }
        // ]
      },
      groups: [],
      contact_dialog: false,
      // search contacts data
      search_contact_dialog: false,
      // fetched restored contacts
      restored_contacts: {},
      // newly searched contacts to add to restored contacts
      selected_contact_list: [],
      search_contacts: {}, // edit Vale From Arry To Object -- Console Error
      multipleSelection: []
    };
  },
  mounted() {
    this.Refresh();
  },
  computed: {
    ...mapGetters({
      loadingValue: ["contacts/usersLoad"],
      searchContactsItems: ["contacts/searchContacts"],
      contactList: ["contacts/contactList"],
      totalContactListCount: ["contacts/totalContactList"]
    }),
    getSelectedIds() {
      return this.selected_contact_list;
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selections(selections) {
      this.selected = Array.from(selections, el => el.email);
    },

    async copyURL() {
      // console.log(this.localization());
      if (this.auth()) {
        let fullNmae = `${this.auth().email}`;
        // let fullNmae = `${this.auth().first_name}_${this.auth().last_name}`;
        try {
          await navigator.clipboard.writeText(
            `${process.env.VUE_APP_FRONT_URL}invitation/${
              this.auth().id
            }/${fullNmae}`
          );
          Swal.fire({
            title: "",
            text: `${this.localization("Done")}`,
            type: "success",
            confirmButtonClass: "btn btn-success",
            confirmButtonText: `${this.localization("Ok")}`,
            buttonsStyling: false,
            icon: "success"
          });
        } catch ($e) {
          console.log("");
        }
      }
    },
    Refresh() {
      this.search_contact_dialog = false;
      this.contact_dialog = false;
      this.$store.dispatch("contacts/GetContacts").then(_ => {
        this.contacts = this.$store.getters["contacts/contacts"];
        this.$store.dispatch("groups/GetUserGroups").then(_ => {
          this.groups = this.$store.getters["groups/userGroups"];
        });
      });
      this.selected_contact_list = [];
      // get search contacts
      this.$store.dispatch("contacts/getSearchContacts");
      this.getCntactsList();
    },
    open_contact_dialog(type, item) {
      this.contact = item;
      this.contact_dialog = true;
      this.type = type;
    },
    save() {
      this.$refs["contactRef"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("contacts/AddContact", { query: this.contact })
              .then(() => {
                this.Refresh();
                this.contact_dialog = false;
              });
          } else {
            this.$store
              .dispatch("contacts/UpdateContact", {
                id: this.contact.id,
                query: this.contact
              })
              .then(() => {
                this.Refresh();
                this.contact_dialog = false;
              });
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    getCntactsList(query) {
      this.$store.dispatch("contacts/GetContactsList", { query: query });
      // console.log(query);
    },

    //? single invitation
    invite_restored_contact(email) {
      this.$store
        .dispatch("contacts/invite_restored_contact", {
          emails: [email]
        })
        .then(() => {
          Swal.fire({
            title: "",
            text: `${this.localization("Done")}`,
            confirmButtonText: `${this.localization("Ok")}`,
            type: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false,
            icon: "success"
          });
        });
    },
    //?  invite all contacts
    invite_all_restored_contact() {
      let all_emails_for_invitation = [];
      let all_restored_contact = this.$store.getters["contacts/contacts"];
      all_restored_contact.forEach(element => {
        all_emails_for_invitation.push(element.email);
      });

      this.$store
        .dispatch("contacts/invite_restored_contact", {
          emails: all_emails_for_invitation
        })
        .then(() => {
          Swal.fire({
            title: "",
            text: `${this.localization("Done")}`,
            confirmButtonText: `${this.localization("Ok")}`,
            type: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false,
            icon: "success"
          });
          all_emails_for_invitation = [];
        });
    },

    saveNewContact() {
      this.$refs["for_add_new_contact"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("contacts/addNewContacts", {
              email: this.for_add_new_contact.email
            })
            .then(res => {
              if (res.status == "Incorrect Email") {
                Swal.fire({
                  text:
                    "This email is not exist you can copy your link and send an invitation",
                  type: "warning",
                  confirmButtonClass: "btn btn-success",
                  icon: "error",
                  buttonsStyling: false
                });
              } else {
                Swal.fire({
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success",
                  icon: "success",
                  buttonsStyling: false
                });
                this.selected_contact_list = [];
                this.for_add_new_contact.email = "";
                this.search_contact_dialog = false;
                this.Refresh();
                this.getCntactsList();
              }
            });
        }
      });
    },

    search(query) {
      this.usersRoles(query);
    },

    usersRoles(search = "", limit = 10) {
      this.loading = true;
      this.$store
        .dispatch("contacts/getSearchContacts", {
          search_text: search
        })
        .then(() => {
          this.search_contacts = this.$store.getters["contacts/searchContacts"];
          this.loading = false;
        });
    },

    remove(id) {
      Swal.fire({
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false,
        icon: "error"
      }).then(date => {
        if (date.isConfirmed) {
          this.$store.dispatch("contacts/delete", id).then(_ => {
            this.Refresh(null);
            Swal.fire({
              title: "",
              text: `${this.localization("Deleted")}`,
              confirmButtonText: `${this.localization("Ok")}`,
              type: "success",
              confirmButtonClass: "btn btn-success",
              icon: "success",
              buttonsStyling: false
            });
          });
        }
      });
    },
    acceptContact(contactId) {
      this.$store
        .dispatch("contacts/changeContactStatus", {
          id: contactId,
          status: 1
        })
        .then(() => {
          this.getCntactsList();
          Swal.fire({
            title: "",
            text: `${this.localization("Done")}`,
            confirmButtonText: `${this.localization("Ok")}`,
            type: "success",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false
          });
        });
    },

    removeContact(contactId) {
      Swal.fire({
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false,
        icon: "error"
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("contacts/changeContactStatus", {
              id: contactId,
              status: 0
            })
            .then(_ => {
              this.Refresh();
              this.getCntactsList();
              Swal.fire({
                title: "",
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                icon: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    closeAddNewContactModal() {
      this.search_contact_dialog = false;
      this.selected_contact_list = [];
      this.for_add_new_contact.email = "";
    }
  }
};
</script>

<style scoped lang="scss">
.main_card_content {
  width: 300px;
  position: relative;
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 5px;

  .test_icon_container {
    transition: 0.5s;
    position: relative;
    overflow: hidden;
    width: 70px;
    height: 70px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #ccc;
    text-align: center;
    position: relative;
    top: -30px;
    line-height: 70px;
    border-radius: 50%;
    .test_icons {
      transition: 0.5s;
      color: #b7b7b7;
      width: auto;
      position: relative;
      z-index: 2;
      line-height: 70px;
      font-size: 30px;
    }
  }
  .test_heading {
    margin: 0;
    text-align: center;
    text-transform: capitalize;
    color: #777;
  }
  .test_text {
    padding: 10px;
    text-align: center;
    color: #a1a1a1;
  }
}

.test_card_test {
  margin: 200px;
  background-color: #eee;
  padding: 10px;
  border-radius: 10px;
  color: #777;
  img {
    width: 100%;
    border-radius: 10px;
    height: 210px;
  }
  i {
    background: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    color: #777;
  }
}

// *  Start server table style in all screens
.server_table_for_big_screens {
  display: block;
  @media (min-width: 320px) and (max-width: 997px) {
    display: none;
  }
}
.server_table_for_mobile_screen {
  display: none;
  @media (min-width: 320px) and (max-width: 997px) {
    display: block;
  }
}
// *  End server table style in all screens
.not-active {
  opacity: 0.4 !important;
}
.shre-link {
  .shre-text {
    color: #707070;
  }
  .user-share-link {
    width: 30px;
    height: 30px;
    line-height: 30px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2), 0 0 5px -2px rgba(0, 0, 0, 0.1);
    margin: 0 10px;
    border-radius: 5px;
    transition: 0.3s all ease-in-out;
    &:hover {
      background-color: #11bfe3;
      color: #fff;
    }
  }
}

$transition: 0.3s all ease-in-out;
$textcolor: #f7f7f8;
$edit-color: #ffa534;
$add-color: #11bfe3;
$cancel-color: #fb404b;
$black: #000;
$white: #fff;
$box-shadow: 0 1px 12px rgba($black, 0.4);
$text-shadow: 2px 4px 3px rgba($black, 0.3);
$simple-shadow: 0 0 6px 0 rgba($black, 0.2), 0 0 5px -2px rgba($black, 0.1);

::v-deep .el-table__row {
  .el-table_1_column_1 {
    padding: 0 !important;
    .cell {
      padding: 0 !important;
      // text-align: center;
    }
    // el-collapse-item__header
  }
}
::v-deep .has-gutter {
  // display: none !important;
}
.actions-icons-container {
  gap: 8px;
  // display: flex;
  .actions-icons {
    font-size: 14px;
    width: 30px;
    height: 30px;
    box-shadow: $simple-shadow;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    cursor: pointer;
    color: #777;
    transition: $transition;
    @media (min-width: 320px) and (max-width: 991px) {
      position: absolute;
      right: 5px;
      top: 5px;
    }
    &.reinvite_contact {
      @media (min-width: 320px) and (max-width: 991px) {
        position: absolute;
        right: 40px;
        top: 5px;
      }
    }
    &.members-group-numbers {
      background-color: $add-color;
      color: $white;
    }
    &.add {
      // margin-bottom: 0.3rem;
      &:hover {
        background-color: $add-color;
        color: $white;
      }
    }
    &.edit {
      &:hover {
        background-color: $edit-color;
        color: $white;
      }
    }
    &.delete {
      &:hover {
        background-color: $cancel-color;
        color: $white;
      }
    }
  }
}

.main-members-container {
  padding: 0.5rem 0.3rem;
  margin-top: 0.7rem;
  position: relative;
  &.for-small {
    padding-bottom: 1rem;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    display: block !important;
  }
  .user-details {
    width: 300px;
    @media (min-width: 767px) and (max-width: 991px) {
      width: 200px;
    }
    @media (min-width: 320px) and (max-width: 767px) {
      width: 100%;
    }
    &.for-small {
      width: auto;
      gap: 50px;
    }
    .part-one {
      color: #777;
      gap: 8px;
      // align-items: center;
      .user-icon {
        font-size: 14px;
        line-height: 2;
      }
      .user-name {
        word-break: break-all;
        transition: $transition;
        color: #707070 !important;
      }
      .user-email {
        word-break: break-all;
      }
    }
  }
}
.main-scores-container {
  // justify-content: flex-start !important;
  .every-score {
    gap: 7px;
    margin-bottom: 8px;
    @media (min-width: 320px) and (max-width: 480px) {
      margin-bottom: 10px !important;
      gap: 10px;
      min-width: 50%;
    }
    .score-word {
      color: #777;
    }
    .score-degre {
    }
  }
  &.main-scores-container-sm {
    @media (min-width: 320px) and (max-width: 767px) {
      margin-top: 15px !important;
    }
  }
}
::v-deep .precision {
  background-color: #3c3;
}

::v-deep .technical_reasoning {
  background-color: #f93;
}

::v-deep .sequence {
  background-color: #39c;
}

::v-deep .confluence {
  background-color: #c36;
}
::v-deep .el-table .cell {
  padding: 4px;
  @media (min-width: 320px) and (max-width: 767px) {
    padding-right: 0;
  }
}
::v-deep .el-table tbody tr td:first-child {
  padding-left: 0;
  @media (min-width: 320px) and (max-width: 767px) {
    padding-left: 0;
  }
}
::v-deep .el-table thead tr th:first-child {
  padding-left: 0;
}

.label {
  padding: 0.5rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #fff;
}

::v-deep .swal2-actions {
  background-color: #eee !important;
}
::v-deep .el-dialog__wrapper .el-dialog {
  width: 60%;
  @media (min-width: 320px) and (max-width: 767px) {
    width: 89%;
  }
  .el-dialog__footer {
    // background-color: #eee;
    .el-button {
    }
  }
}
.main_dialog_footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  @media (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
  }
  .for_c_buttons {
    width: 120px;
  }
}
.header_contact_wrapping {
  @media (min-width: 320px) and (max-width: 480px) {
    display: block !important;
  }
  .add_contact_buttons {
    @media (min-width: 320px) and (max-width: 480px) {
      margin: 15px 0 10px;
    }
  }
}
.contact_title {
  &.is_rtl {
    display: flex;
    gap: 8px;
    flex-direction: row-reverse;
    align-items: center;
  }
}
</style>

<style lang="scss">
.invite_contact_with_rtl {
  .el-form-item__label {
    width: 100%;
  }
  .el-input__inner {
    text-align: right;
  }
}
</style>
